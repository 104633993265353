.mobile-sidebar {
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 100%;
  height: calc(100% - 60px);
  background-color: var(--primary-background);
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  padding: 10px;


}

.mobile-sidebar.open {
  transform: translate(-50%, 0);
}

.mobile-sidebar.collapsed {
  transform: translate(-50%, -100%);
}

.mobile-sidebar .section-divider {
  width: calc(100% - 10px);
}

.sidebar {
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100% - 64px);
  width: 250px;
  background-color: var(--primary-background);
  overflow-y: auto;
  transition: width 0.3s ease;
  z-index: 999;
  padding: 5px;
}
.alert-icon-container {
  position: relative;
  left: 50px;
  top: 15%; 
  transform: translateY(-15%);
  font-size: 20px;
  color: red;
}

.sidebar.collapsed .alert-icon-container {
  visibility: hidden;
}
.kofiContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 135px;
}
/* Ko-fi modal fix */
.kofi-widget-modal {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  bottom: auto !important;
  right: auto !important;
  z-index: 9999;
}


.sidebar.collapsed {
  width: 40px;
  text-align: center;
}
.copyright-main p {
  font-size: 14px !important;
  color: #fff !;
}

.copyright-sub p {
  font-size: 12px !important;
  color: #fff !important;
}

.country-item,
.sidebar-menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--text-color);
  font-size: 16px;
  text-decoration: none;
  font-weight: 600 !important;

}

.sidebar-menu-item span {
  margin-left: 10px;
}

.sidebar.collapsed .sidebar-menu-item {
  justify-content: center;
}

.sidebar.collapsed .sidebar-menu-item span {
  display: none;
}

.sidebar-menu-item:hover {
  background-color: var(--hover-background);
}

.sidebar-items {
  margin-top: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;

}

.section-divider {
  border: none;
  height: 2px;
  background-color: #333;
  margin: 10px auto;
}



.popular-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-section {
  color: var(--secondary-text-color);
  font-size: 10px;
  margin-left: 10px;
}

.collapsed-header {
  display: none;
  font-size: 20px;
  color: var(--secondary-text-color);
  text-align: center;
  width: 100%;
}

.sidebar.collapsed .collapsed-header {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
}

.sidebar-items {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  transition: max-height 0.3s ease;
}

.sidebar-items::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.sidebar-items {
  scrollbar-width: none;
  -ms-overflow-style: none;
}




.country-item:hover {
  background-color: var(--hover-background);
}

.flag-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.browsetext-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  width: auto;
  transition: width 0.5s ease, opacity 0.5s ease;
}

.country-name,
.region-name {
  margin: 0;
  padding: 0;
  text-transform: none;
  line-height: 1;
  font-weight: 600 !important;


}

.country-name {
  font-size: 14px;
  color: var(--text-color);
  letter-spacing: 0.5px;
  font-weight: 500;
  transition: font-size 0.3s;
}

.region-name {
  font-size: 13px;
  color: #ccc;
  font-weight: 500;
}


.sidebar.collapsed .browsetext-container {
  opacity: 0;
  visibility: hidden;
  width: 0;
  transition: width 0.5s ease, opacity 0.5s ease, visibility 0s 0.5s;
  display: none;

}




.sidebar.collapsed .country-item {
  justify-content: center;
  max-width: 50px;

}



.sidebar.collapsed .flag-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 10px;

}
.sidebarFooter {
  position: absolute;
  bottom: 15px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  padding: 10px 15px;
  box-sizing: border-box;
  text-align: center;
  transition: opacity 0.3s ease;
}

.footerLink {
  color: #68dfdfbb;
  text-decoration: none;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.footerLink:hover {
  color: #0786c5;
  text-decoration: none;
}

.footerIcon {
  font-size: 25px;
}

.sidebar.collapsed .footerIcon {
  margin-left: -5px !important;
}

@media screen and (min-width: 769px) {
  .sidebar-menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    color: var(--text-color);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

  }

  .sidebar-menu-item:hover {
    background-color: var(--hover-background);
  }

  .sidebar-menu-item span {
    margin-left: 10px;
  }

  .section-divider {
    border: none;
    height: 2px;
    background-color: #333;
    margin: 10px 0;
  }

  .popular-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .left-section {
    font-size: 10px;
    color: rgb(223, 223, 223);
  }

  .collapsed-header {
    display: none;
  }

  .collapsed-header.show {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-top: 10px;
    color: var(--secondary-text-color);
  }

  .sidebar-items::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .sidebar-items {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

}