.stream-checker-container {
  padding: 10px;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  color: white;
  width: 100%;
  background-color: var(--content-background);

}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}


.form-group select {
  padding: 8px 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background: #2e2e2e;
  color: #ffffff;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23fff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.form-group select:focus {
  outline: none;
  border-color: #00aaff;
}

.logo {
  width: 50px;
  height: auto;
  margin-right: 15px;
}

h1 {
  font-size: 20px;
  margin: 0;
  color: white;
}

.search-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.search-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  transition: all 0.3s ease;
}

.search-input::placeholder {
  color: #bbb;
}

.search-input:focus {
  border-color: #0078d7;
  outline: none;
  background-color: #222;
}
.filter-container {
  margin-bottom: 15px;
}

.filter-select {
  padding: 5px;
}


.restore-container {
  text-align: right;
  flex-grow: 2;
}

.restore-button {
  background-color: #dc3545;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;

}

.restore-button:hover {
  background-color: #c82333;
}


.stream-columns-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

}

.stream-header {
  font-size: 20px;
  font-weight: bold;
  flex: 1;
  text-align: center;
  color: white;
}

.stream-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;

}

.stream-column {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  max-height: 80vh;
  overflow-y: auto;

}

.stream-item {
  position: relative;
  background-color: #1e1e1e;
  border-radius: 12px;
  padding: 16px;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 95%;
  height:  150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out;
}
.stream-item:hover {
  transform: scale(1.01);
}
.stream-thumbnail {
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  flex-shrink: 0;
}

.online {
  background-color: #d4edda;
  border-left: 5px solid #28a745;
}

.offline {
  background-color: #f8d7da;
  border-left: 5px solid #dc3545;
}


.stream-info {
  flex: 1;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 30%;
  gap: 4px;
  text-align: left;
}

.stream-buttons {
  display: grid;
  grid-template-columns: repeat(3, minmax(120px, 1fr));
  gap: 10px;
  margin-top: 10px;
  justify-items: center;
  width: 100%;
}

.stream-buttons button {
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.stream-buttons a {
  width: 100%;
  text-align: center;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  
  /* New additions for centering */
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none; /* Remove underline */
  color: white; /* Or your preferred color */
}

/* Consistent colors and hover effects */
.toggle-status-button {
  background-color: #444;
  color: white;
}

.toggle-status-button:hover {
  background-color: #ff8700;
  color: black;
}

.fallbackButton {
  background-color: #555;
  color: white;
}

.fallbackButtonGreen {
  background-color: #ff8700;
  color: black;
}
.fallbackButtonGreen:hover {
  background-color: rgb(231, 173, 12);
  color: black;
}
.channel-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.channel-thumbnail {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.filter-select {
  padding: 10px 12px;
  background-color: #2e2e2e; /* dark background */
  color: #fff; /* white text */
  border: 1px solid #444;
  border-radius: 6px;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23ffffff" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease;
}

.filter-select:focus {
  outline: none;
  border-color: #00aaff;
  background-color: #1e1e1e;
}

.channel-name {
  font-size: 0.85rem;
  color: black;
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 6px;
}

.partner-icon {
  width: 18px;
  height: 18px;
}
.fallbackButtonRed {
  background-color: rgb(131, 223, 128);
  color: black;
}
.fallbackButtonRed:hover {
  background-color: rgb(27, 136, 23);
  color: white;
}

.check-stream-button {
  background-color: #329dcf;
  color: white;
}

.check-stream-button:hover {
  background-color: #267ba9;
}

.youtube-search-button {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.youtube-search-button:hover {
  background-color: rgb(221, 101, 101);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.youtube-icon {
  width: 20px;
  height: 20px;
}

.edit-button {
  background-color: rgb(120, 158, 175);
  color: black;
}

.edit-button:hover {
  background-color: #506079;
  color: white;
}

.watch-button {
  background-color: rgb(201, 74, 74);
  color: white;
  text-decoration: none;
}

.watch-button:hover {
  background-color: rgb(192, 129, 129);
}

.delete-button {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: red;
  border: none;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.delete-button:hover {
  background-color: darkred;
}

.delete-button i {
  font-size: 15px;
}


.stream-info h3 {
  font-size: 16px;
  margin: 0;
  color: #333;
}



.stream-column::-webkit-scrollbar {
  display: none;
}

.stream-column {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  height: 90%;
  max-height: 90%;
  background: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.status-toggle {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.status-online {
  background-color: green;
  color: white;
}

.status-offline {
  background-color: red;
  color: white;
}

.modal-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: #ffffff;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.form-group input {
  padding: 8px 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background: #2e2e2e;
  color: #ffffff;
  font-size: 1rem;
}

.form-group input:focus {
  outline: none;
  border-color: #00aaff;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.close-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.save-button {
  background: #00aaff;
  color: #ffffff;
}

.save-button:hover {
  background: #0088cc;
}

.close-button {
  background: #444;
  color: #ffffff;
}

.close-button:hover {
  background: #555;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
  .stream-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .stream-buttons {
    justify-content: flex-start;
  }

  .stream-thumbnail {
    width: 100%;
    height: auto;
  }
  .form-grid {
    grid-template-columns: 1fr;
  }
}

