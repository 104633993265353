.country-profile-editor-container {
  width: 100%;
  min-height: 100vh;
  padding: 24px;
  background-color: var(--content-background);
  color: var(--text-color);
  box-sizing: border-box;
  
}

/* Header styles */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;
}

.search-container {
  flex: 1;
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
}

.search-input {
  width: 100%;
  max-width: 300px;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid #444;
  background-color: #222;
  color: #fff;
  font-size: 0.95rem;
}

.search-input::placeholder {
  color: #888;
}

/* Grid layout for profiles */
.profiles-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 24px;
  width: 100%;
  padding-right: 10px;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #555 #333;
  overflow-y: auto;
  max-height: calc(100vh - 200px); /* Adjust based on header height */
}

.profiles-list::-webkit-scrollbar {
  width: 8px;
}

.profiles-list::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 8px;
}

.profiles-list::-webkit-scrollbar-track {
  background-color: #333;
}

/* Profile Card */
.profile-item {
  background-color: #1e1e1e;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  aspect-ratio: 1 / 1.2;
  box-sizing: border-box;

}

.profile-item:hover {
  transform: scale(1.03);
}

.flag-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.flag-image {
  width: 80px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.15);
}

.country-name {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 6px 0 4px;
  text-align: center;
  color: #fff;
}

.country-capital {
  font-size: 0.9rem;
  color: #ccc;
  text-align: center;
  margin-bottom: 10px;
}

.profile-actions {
  display: flex;
  gap: 10px;
  margin-top: auto;
}

.edit-button,
.delete-button {
  padding: 8px 12px;
  font-size: 0.85rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.edit-button {
  background-color: var(--earf-blue);
  color: black;
  font-weight: 600;
}

.edit-button:hover {
  background-color: #0056b3;
}


/* Remove delete button styling */
.delete-button {
  display: none;
}

.delete-button:hover {
  background-color: #b02a37;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  height: 90%;
  max-height: 90%;
  background: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.6);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

/* Form inside modal */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.form-group label {
  color: #ddd;
  margin-bottom: 6px;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-group input {
  padding: 10px;
  font-size: 0.95rem;
  border: 1px solid #444;
  border-radius: 6px;
  background-color: #2a2a2a;
  color: #fff;
}

.form-group input:focus {
  outline: none;
  border-color: #00aaff;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.save-button,
.close-button {
  padding: 10px 18px;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.save-button {
  background-color: #28a745;
  color: #fff;
}

.save-button:hover {
  background-color: #218838;
}

.close-button {
  background-color: #444;
  color: #fff;
}

.close-button:hover {
  background-color: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: 100%;
  }

  .header-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .profile-actions {
    flex-direction: column;
    width: 100%;
  }

  .edit-button,
  .delete-button {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-container {
    width: 100%;
    justify-content: flex-start;
  }

  .search-input {
    max-width: 100%;
  }
}
