.video-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto;

}

.video-info-container {
  flex: 0 0 80%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  box-sizing: border-box;
  background-color: aqua !important;
}


.video-container {
  width: 100%;
  height: calc(100% - 150px);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.main-video-content {
  flex-grow: 1;
  height: 100%;
  transition: all 0.3s ease;
  margin: 0 auto;
  padding: 0;
  position: relative;
  overflow-y: scroll;
  width: 95%;
}

.main-video-content::-webkit-scrollbar {
  display: none;
}

.wikidataText {
  font-size: 14px;
  font-weight: 600;
}

.main-video-content {
  scrollbar-width: none;
  background-color: var(--content-background);

}

.main-video-content {
  -ms-overflow-style: none;
}


.video-info {
  width: 98%;
  box-sizing: border-box;
  color: white;
  z-index: 10;
  margin-top: 5px;
  margin-left: 15px;

}

.channel-info {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.channel-info .back-button {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 10px 10px;
  font-size: 16px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.mini-player {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 170px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mini-player-container {
  position: relative;
  width: 100%;
  height: 100%;

}

.mini-player-video {
  width: 100%;
  height: 100%;
}

.mini-player-stop {
  position: absolute;
  top: 0px;
  right: 0px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.mini-player-enlarge {
  position: absolute;
  top: 0px;
  right: 50px;
  background: rgb(154, 214, 147);
  border: none;
  padding: 5px;
  padding-left: 10px;
  color: black;
  font-size: 13px;
  cursor: pointer;
}

.mini-player-enlarge i {
  margin-right: 5px;
}

#channel-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 8px;
}

.channel-details {
  flex-grow: 1;
}

.channel-info h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}


.channel-info a {
  color: var(--secondary-text-color);
  text-decoration: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.channel-info a:hover {
  text-decoration: none;
  color: rgb(131, 173, 173);
}

.feature-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 0;
}

.feature-tag {
  background-color: var(--components);
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.feature-tag:hover {
  color: var(--earf-blue);
}


.video-info p {
  margin: 0;
  text-align: left;
  color: white;
  width: 100%;
}

.video-info h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: white;
  width: 100%;

}

.channel-info .website-link {
  color: rgb(204, 204, 204) !important;
  font-size: 16px;
}

.channel-info .website-link:hover {
  color: var(--secondary-text-color) !important;
  text-decoration: none !important;
}

.info-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

}

.info-left {
  flex: 1;

}


.info-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.location-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.navigate-to-map-container {
  width: 100%;
  text-align: right;
}

.navigate-to-map-button {
  background-color: var(--secondary-background);
  color: #ccc;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navigate-to-map-button:hover {
  background-color: #444;
  color: #fff;
}
.video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blurred-thumbnail {
  filter: blur(5px);
  opacity: 0.7;
  transition: all 0.3s ease;
}

.censored-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* <-- IMPORTANT */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.censored-warning-head {
  color: red;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.censored-warning-text {
  color: white;
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
  max-width: 80%;
}

.accept-button {
  background-color: var(--earf-blue);
  color: black;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, filter 0.3s ease;
}

.accept-button:hover {
  background-color: #89d1d1bb;
  filter: brightness(1.1);
}


@media (max-width: 992px) {

  .video-view {
    height: 100%;
    width: 100%;
    padding-bottom: 10px;
    flex-direction: column;
    overflow-y: scroll;
  }

  .video-container {
    height: auto;
    padding: 5px;
    margin-top: 10px;
    margin-left: 0px;
    min-width: 100%;

  }

  .video-container iframe {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 8px;
  }

  .video-info {
    padding-top: 10px;
    box-sizing: border-box;
    color: white;
    margin-left: 0px;
    min-width: 100%;

  }

  #channel-thumbnail {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }

  .channel-info {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 15px;
  }


  .channel-details h4 {
    font-size: 14px;
  }

  .feature-tags {
    gap: 5px;
  }

  .feature-tag {
    font-size: 11px;
    padding: 4px 8px;
  }

  .mobile-back-button {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    padding: 10px;
    font-size: 14px;
    background-color: var(--secondary-background);
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
    align-self: center;

  }

  .info-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-right {
    justify-content: flex-start;
    width: 100%;
  }

  .video-view {
    flex-direction: column;
    width: 100%;
  }

  .video-info-container,
  .similar-videos-sidebar {
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  .navigate-to-map-container {
    display: none;
  }
}
